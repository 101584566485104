import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ExpandContent from "../common/ExpandContent";
import ExpandHeading from "../common/ExpandHeading";
import { allMagazines } from "../constants/DataObject.js/AllMagazine";

function AllTopicsWrapper({ column }) {
  const [expandOpened, setExpandOpened] = useState();

  return (
    <>
      <Row className="AllTopics_ExpandRow">
        <Col sm={12} md={6} lg={6}>
          <div className="AllTopics_Expand">
            <ExpandHeading
              columnName={column}
              expandOpened={expandOpened}
              setExpandOpened={setExpandOpened}
            />
          </div>
          {allMagazines.magazines
            .map((magazine) =>
              magazine.magazineInfo.filter((mag) => mag.columnName === column)
            )
            .map(
              (info) =>
                !!info.length && (
                  <div
                    className="AllTopics_TopicNameColumn"
                    key={`${info[0].topic[0]}-${info[0].author[0]}`}
                  >
                    <ExpandContent
                      expandOpened={expandOpened}
                      magazineInfo={info[0]}
                    />
                  </div>
                )
            )}
        </Col>
      </Row>
    </>
  );
}

export default AllTopicsWrapper;
