import React from "react";
import { AiFillFacebook } from "react-icons/ai";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div className="ContactUs">
      <h3>
        <span className="ContactUs_Heading">برائے رابطہ</span>
      </h3>
      <div className="ContactUs_Content">
        <p className="ContactUs_AddressFirst">جاری کردہ:</p>
        <p className="ContactUs_AddressSecond">
          مدرسہ انوار القرآن، قادریہ رضویہ
        </p>
        <p className="ContactUs_AddressThird">
          جامع مسجد عثمان غنی، ٹھٹھائی کمپاونڈ، لائٹ ہاوس، کراچی
        </p>

        <p className="ContactUs_Icons">
          <p className="email_text">m.anwarulquran@gmail.com</p>|
          <p>
            <a href="https://www.facebook.com/MadarsaAnwarulQuraan">
              <AiFillFacebook className="facebook_icon" />
            </a>
          </p>
          |
          <p>
            <span className="phone_text"> 0332-8205566</span>
          </p>
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
