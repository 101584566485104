export const urduMonthsToEnglish = {
  جنوری: "January",
  فروری: "February",
  مارچ: "March",
  اپریل: "April",
  مئی: "May",
  جون: "June",
  جولائی: "July",
  اگست: "August",
  ستمبر: "September",
  اکتوبر: "October",
  نومبر: "November",
  دسمبر: "December",
  "نومبر/دسمبر": "November_December",
  "مارچ/اپریل": "March_April",
  "ستمبر/اکتوبر": "September_October",
};

export const aboutUsContent = [
  {
    id: 1,
    heading: ":مستفید ہونے والا وسیع حلقہ قارئین",
    paragraph:
      "اپریل2000ء سے اب تک اس ماہنامہ کو 18سال مکمل ہو چکےہیں اور آج یہ ماہنامہ فی الواقع دینی و ملی صحافت میں کثیر الاشاعت ماہنامہ بن چکا ہے۔ جس کی ایک لاکھ کاپیاں ہر مہینے شائع ہو رہی ہیں جو ادارہ کی محنت، مسلسل کوشش اور مستقل مزاجی کا منہ بولتا ثبوت ہے۔ ماہنامہ کے نمائندے پاکستان کےتقریباًتمام اضلاع میں موجود ہیں جوعوام الناس تک نہ صرف مذکورہ ماہنامہ کوپہنچاتےہیں بلکہ تمام علمی حلقوں، پاکستان کی تمام چھوٹی بڑعی جامعات، تعلیمی اداروں اور لائبریریز تک رسائی  بہم پہنچاتےہیں۔پاکستان کے علاوہ10  بیرون ممالک میں بھی ماہنامہ کے نمائندے موجود ہیں۔ ماہنامہ مر اۃ العارفین اپنے قارئین ",
  },
  {
    id: 2,
    heading: ":ماہنامہ مر اۃ العارفین کےاغراض ومقاصد",
    paragraph:
      "اپریل2000ء سے اب تک اس ماہنامہ کو 18سال مکمل ہو چکےہیں اور آج یہ ماہنامہ فی الواقع دینی و ملی صحافت میں کثیر الاشاعت ماہنامہ بن چکا ہے۔ جس کی ایک لاکھ کاپیاں ہر مہینے شائع ہو رہی ہیں جو ادارہ کی محنت، مسلسل کوشش اور مستقل مزاجی کا منہ بولتا ثبوت ہے۔ ماہنامہ کے نمائندے پاکستان کےتقریباًتمام اضلاع میں موجود ہیں جوعوام الناس تک نہ صرف مذکورہ ماہنامہ کوپہنچاتےہیں بلکہ تمام علمی حلقوں، پاکستان کی تمام چھوٹی بڑعی جامعات، تعلیمی اداروں اور لائبریریز تک رسائی  بہم پہنچاتےہیں۔پاکستان کے علاوہ10  بیرون ممالک میں بھی ماہنامہ کے نمائندے موجود ہیں۔ ماہنامہ مر اۃ العارفین اپنے قارئین ",
  },
  {
    id: 3,
    heading: ": صلائے عام",
    paragraph:
      "اپریل2000ء سے اب تک اس ماہنامہ کو 18سال مکمل ہو چکےہیں اور آج یہ ماہنامہ فی الواقع دینی و ملی صحافت میں کثیر الاشاعت ماہنامہ بن چکا ہے۔ جس کی ایک لاکھ کاپیاں ہر مہینے شائع ہو رہی ہیں جو ادارہ کی محنت، مسلسل کوشش اور مستقل مزاجی کا منہ بولتا ثبوت ہے۔ ماہنامہ کے نمائندے پاکستان کےتقریباًتمام اضلاع میں موجود ہیں جوعوام الناس تک نہ صرف مذکورہ ماہنامہ کوپہنچاتےہیں بلکہ تمام علمی حلقوں، پاکستان کی تمام چھوٹی بڑعی جامعات، تعلیمی اداروں اور لائبریریز تک رسائی  بہم پہنچاتےہیں۔پاکستان کے علاوہ10  بیرون ممالک میں بھی ماہنامہ کے نمائندے موجود ہیں۔ ماہنامہ مر اۃ العارفین اپنے قارئین ",
  },
];

export const months = {
  JANUARY: "جنوری",
  FEBRUARY: "فروری",
  MARCH: "مارچ",
  APRIL: "اپریل",
  MAY: "مئی",
  JUNE: "جون",
  JULY: "جولائی",
  AUGUST: "اگست",
  SEPTEMBER: "ستمبر",
  OCTOBER: "اکتوبر",
  NOVEMBER: "نومبر",
  DECEMBER: "دسمبر",
  SEPTEMBER_OCTOBER: "ستمبر/اکتوبر",
  NOVEMBER_DECEMBER: "نومبر/دسمبر",
  MARCH_APRIL: "مارچ/اپریل",
};

export const years = {
  2020: "2020",
  2021: "2021",
  2022: "2022",
};

export const columns = {
  IDARIA: "اداریہ",
  MANSOOSAAT: "منصوصات",
  SHARYI_REHNUMAAI: "شرعی رہنمائی",
  FIKR_O_NAZAR: "فکر و نظر",
  TABADLA_E_KHYAL: "تبادلئہ افکار",
  FEHM_MAGHRIB: "فہم مغرب",
  MUTALA_E_SEERAT: "مطالعئہ سیرت",
  TANQEEHAAT: "تنقیحات",
  FIQH_ALMALIYAAT: "فقہ المالیات",
  MUKAAMLA: "مکالمہ",
  AAINA_WATAN: "آئینہ وطن",
  TARJUMA: "ترجمہ",
  ANWAAR_E_HAYAAT: "انوار حیات",
  BAZM_E_KHYAL: "بزم خیال",
  JAHAN_ADAB: "جہان ادب",
  TEHLEEL_O_TAJZIYA: "تحلیل و تجزیہ",
  DARSIYAAT: "درسیات",
  EHD_E_HAZIR: "عہد حاضر",
  SARGAZASHT: "سرگزشت",
  SIYAASIYAAT: "سیاسیات",
};

export const authors = {
  M_ANAS_RAZA: "محمد انس رضا قادری",
  HAFIZ_AHMED: "حافظ احمد",
  MUFTI_MUHAMMAD_FARHAN: "مفتی محمد فرحان قادری",
  ALLAMA_ASIF_IQBAL: "علامہ آصف اقبال مدنی",
  ULMA_WA_DANISH: "علماء و دانشوران",
  ABDUL_NABI: "عبد النبی السندی",
  SHAHZAIB_RAAJPAR: "شاہزیب راجپر",
  ALLAMA_SHOAIB_MADNI: "علامہ شعیب مدنی",
  ALLAMA_MUHAMMAD_SALMAN: "علامہ محمد سلمان رضا قادری",
  M_BILAL_RIZVI: "محمد بلال رضوی اشرفی",
  FURQAN_SHABBIR: "فرقان شبیر",
  ALLAMA_M_ANAS: "علامہ محمد انس بندیالوی",
  ALLAMA_M_SAAD: "علامہ محمد سعد موسانی",
  M_NADEEM_ATTARI: "محمد ندیم عطاری مدنی",
  ALLAMA_M_JAVED: "علامہ محمد جاوید قادری",
  BABR_RAUF: "بابر عارف",
  GHULAM_RAZA_QADRI: "غلام رضا قادری",
  BILAL_HANFI: "بلال حنفی",
  ALLAMA_OSAMA_QADRI: "ابو الحسنین علامہ اسامہ قادری",
  ALLAMA_ASJAD_NOMAN: "علامہ عسجد نعمان قادری",
  M_RIZWAN_TAHIR: "محمد رضوان طاہر فریدی",
};
