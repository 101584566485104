import React from "react";
import { Col, Row } from "react-bootstrap";
import "./AllTopics.css";
import { columns } from "../constants/maps";
import AllTopicsWrapper from "../components/AllTopicsWrapper";

// const getAllTopicsApi = () => Api.get(`/magazine/allTopics`, null,  { headers: false });

const AllTopics = () => {
  // const { data, loading, error, request } = useApi(getAllTopicsApi);

  // useEffect(() => {
  //     request();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="container">
      <Row className="AllTopics_TopicsHeadingWrapper">
        <Col sm={3} md={4} lg={2}>
          <h5 className="AllTopics_TopicsHeading">عنوانات / مضامین</h5>
        </Col>
      </Row>

      {Object.values(columns).map((column, idx) => (
        <AllTopicsWrapper column={column} />
      ))}
    </div>
  );
};

export default AllTopics;
