import React from "react";
import { useLocation } from "react-router-dom";
import "./TopicReading.css";

const TopicReading = () => {
  const { state } = useLocation();

  return (
    <div className="TopicReading">
      <h3>
        <span className="TopicReading_Name">{state.topic}</span>
      </h3>
      <h5 className="TopicReading_AuthorName">مصنف: {state.author}</h5>
      <p className="TopicReading_Paragraph">{state.content}</p>
    </div>
  );
};

export default TopicReading;
