import React, { useState } from "react";
import "./Expand.css";
import ExpandContent from "./ExpandContent";
import ExpandHeading from "./ExpandHeading";

const Expand = ({ magazineInfo }) => {
  const [expandOpened, setExpandOpened] = useState();

  return (
    <>
      <div className="Expand_Row">
        <ExpandHeading
          columnName={magazineInfo.columnName}
          expandOpened={expandOpened}
          setExpandOpened={setExpandOpened}
        />
      </div>

      <ExpandContent expandOpened={expandOpened} magazineInfo={magazineInfo} />
    </>
  );
};

export default Expand;
