import React from "react";
import { ReactComponent as AppLogo } from "../assets/images/logo_1.svg";
import { ReactComponent as AppLogoMain } from "../assets/images/logo_2.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./AppNavbar.css";
import { Link, useNavigate } from "react-router-dom";

const AppNavbar = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="AppNavbar_MainHeading">
        <AppLogoMain className="AppLogoMain" />
      </div>
      <Navbar expand="lg" className="AppNavbar">
        <Container className="AppNavbar_Container">
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span>
              <GiHamburgerMenu className="AppNavbar_HamburgerIcon" />
            </span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto AppNavbar_Link">
              <Nav.Link className="AppNavbar_LinkItem" as={Link} to="/">
                پہلا صفحہ
              </Nav.Link>
              {/* 
              <Nav.Link className="AppNavbar_LinkItem" as={Link} to="/about">
                تعارف
              </Nav.Link> */}

              <Nav.Link
                className="AppNavbar_LinkItem"
                as={Link}
                to="all-topics"
              >
                تمام مضامین
              </Nav.Link>

              <Nav.Link
                className="AppNavbar_LinkItem"
                as={Link}
                to="all-magazines"
              >
                میگزین محفوظہ
              </Nav.Link>

              <Nav.Link className="AppNavbar_LinkItem" as={Link} to="contact">
                برائے رابطہ
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div href="/" className="Logo_Wrapper">
            <AppLogo className="Logo" onClick={() => navigate("/")} />
            <span className="Logo_Text">کراچی</span>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default AppNavbar;

// <nav classNameName="Navbar">
//   <div classNameName="Hamburger_Menu">
//   <div classNameName="Hamburger_MenuIcon">
//       <NavLink to="/" onClick={() => setShowMediaIcons(!showMediaIcons)}>
//         <GiHamburgerMenu />
//       </NavLink>
//     </div>
//   </div>

//   <div
//     classNameName={
//       showMediaIcons ? "Navbar_Menu Navbar_Menu-mobile" : "Navbar_Menu"
//     }>
//     <ul>
//       <li>
//         چ
//       </li>
//       <li>
//         <NavLink to="/all-magazines">میگزین محفوظہ</NavLink>
//       </li>
//       <li>
//         <NavLink to="/all-topics">تمام مضامین</NavLink>
//       </li>
//       <li>
//         <NavLink to="/about">تعارف</NavLink>
//       </li>
//       <li>
//         <NavLink to="/">پہلا صفحہ</NavLink>
//       </li>
//     </ul>
//   </div>

//     {/* hamburget menu start  */}
//     <div classNameName="Logo">
//     <AppLogo />
//   <AppLogoBody />
//   </div>
// </nav>
