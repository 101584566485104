import React from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "react-transition-group";
import "./ExpandContent.css";

const duration = 600;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const ExpandContent = ({ expandOpened, magazineInfo }) => {
  const navigate = useNavigate();

  const handleClick = (topic, author, content) => {
    navigate("/topic", { state: { topic, author, content } });
  };

  return (
    <Transition in={expandOpened} timeout={duration}>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {expandOpened &&
            magazineInfo.topic.map((top, idx) => (
              <div className="ExpandContent_Wrapper" key={idx}>
                <button
                  className="ExpandContent_Text"
                  onClick={() =>
                    handleClick(
                      top,
                      magazineInfo.author[idx],
                      magazineInfo.content[idx]
                    )
                  }
                >
                  {magazineInfo.topic[idx]}
                </button>
                <h3>مصنف: {magazineInfo.author[idx]}</h3>
              </div>
            ))}
        </div>
      )}
    </Transition>
  );
};

export default ExpandContent;
