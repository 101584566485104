import { Route, Routes, useLocation } from "react-router-dom";
import MagazineInfo from "./Pages/ MagazineInfo";
import TopicReading from "./Pages/TopicReading";
import Home from "./Pages/Home";
import AllTopics from "./Pages/AllTopics";
import AllMagazines from "./Pages/AllMagazines";
import AppNavbar from "./Navbar/AppNavbar";
import ContactUs from "./Pages/ContactUs";

const AppRouter = () => {
  const { pathname } = useLocation();

  return (
    <>
      {!["/login", "/admin"].includes(pathname) && <AppNavbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/magazine-info/:magazineId" element={<MagazineInfo />} />
        <Route path="/topic" element={<TopicReading />} />
        <Route path="/all-topics" element={<AllTopics />} />
        <Route path="/all-magazines" element={<AllMagazines />} />
        {/* <Route path="/about" element={<AboutUs />} /> */}
        <Route path="/contact" element={<ContactUs />} />

        {/* <Route path="/login" element={<Login />} />
              <Route path="/admin" element={<MagazineInfoForm />} /> */}
      </Routes>
    </>
  );
};

export default AppRouter;
