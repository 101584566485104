import React from 'react'
import './DividerWithIcon.css'

function DividerWithIcon({Icon, text}) {
    return (
        <div className="DividerWithIcon">
            <h1 className="DividerWithIcon_Heading">{text}</h1>
                <h2 className="DividerWithIcon_BorderWrapper">
                    <span className="DividerWithIcon_IconWrapper">
                        <Icon className="DividerWithIcon_Icon" />
                    </span>
                </h2>
        </div>
        
    )
}

export default DividerWithIcon
