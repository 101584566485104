import React from 'react';
import './Button.css'

function Button({children, onClick}) {
    return (
        <div className="Button_Wrapper">
            <button className="Button" onClick={onClick}>{children}</button>
        </div>
    )
}

export default Button
