import React from "react";
import { Col, Row } from "react-bootstrap";
import Expand from "../common/Expand";
import { HiOutlineDownload } from "react-icons/hi";
import DividerWithIcon from "../common/DividerWithIcon";
import { BiBorderNone } from "react-icons/bi";
import { allMagazines } from "../constants/DataObject.js/AllMagazine";
import Button from "../common/Button";
import "./MagazineInfo.css";
import { urduMonthsToEnglish } from "../constants/maps";
import { useParams } from "react-router-dom";

// const getMagazineByIdApi = (magazineId) => Api.get(`/magazine/${magazineId}`, null,  { headers: false });

const MagazineInfo = () => {
  const { magazineId } = useParams();
  let magazine = allMagazines.magazines.find(
    ({ id }) => id === Number(magazineId)
  );

  return (
    <div className="container">
      <DividerWithIcon
        text={`مخزنِ علم - ${magazine.month} ${magazine.year}`}
        Icon={BiBorderNone}
      />
      <Row className="MagazineInfo_ButtonRow">
        <Col className="MagazineInfo_ButtonColumn">
          <Button>
            <a
              href={`${process.env.PUBLIC_URL}/pdfs/${
                urduMonthsToEnglish[magazine.month]
              }-${magazine.year}.pdf`}
              download={`Makhzan-e-Ilm(${urduMonthsToEnglish[magazine.month]}-${
                magazine.year
              })`}
            >
              PDF ڈاونلوڈ کریں{" "}
              <HiOutlineDownload className="MagazineInfo_ButtonIcon" />
            </a>
          </Button>
        </Col>
      </Row>
      <Row className="Magazine_Info container">
        <Col className="MagazineInfo_ExpandWrapper" md={12} lg={6}>
          <h5 className="MagazineInfo_ColumnHeading">کالمز</h5>
          {magazine.magazineInfo.length === 0 ? (
            <div className="MagazineInfo_NoContent">
              عنقریب مواد اپلود کر دیا جائیگا، جب تک PDF ڈاونلوڈ کر کے اس سے پڑھ
              لیجیے۔
            </div>
          ) : (
            magazine.magazineInfo.map((info) => (
              <Expand key={info.id} magazineInfo={info} />
            ))
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MagazineInfo;
