import React from "react";
import Card from "../common/Card";
import DividerWithIcon from "../common/DividerWithIcon";
import { BiBorderNone } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { urduMonthsToEnglish } from "../constants/maps";
import "./AllMagazines.css";
import { allMagazines } from "../constants/DataObject.js/AllMagazine";

// const getAllMagazinesApi = () => Api.get("/magazine/getAll", null,  { headers: false });

const AllMagazines = () => {
  const navigate = useNavigate();

  // const { data, loading, error, request } = useApi(getAllMagazinesApi);

  // useEffect(() => {
  //     request();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="container">
      <DividerWithIcon text="گزشتہ شمارے" Icon={BiBorderNone} />
      <Row className="AllMagazines_Cards">
        {allMagazines.magazines
          .filter((magazine) => !magazine.currentMonth)
          .map((magazine) => (
            <Col key={magazine.id} xs={8} sm={6} md={6} lg={4} xl={3}>
              <Card
                key={magazine.id}
                onClick={() => navigate(`/magazine-info/${magazine.id}`)}
              >
                <img
                  height="100"
                  loading="lazy"
                  src={`${process.env.PUBLIC_URL}/${
                    urduMonthsToEnglish[magazine?.month]
                  }-${magazine?.year}.jpg`}
                  alt={"Coming Soon"}
                  className="check"
                />
              </Card>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default AllMagazines;
