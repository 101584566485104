import React from 'react';
import './Card.css'

const Card = ({ onClick, children }) => {
    return (
        <div className="Card"  onClick={onClick}>
            <div className="Card_Row">
                {children}
            </div>
        </div>
    )
}

export default Card;
