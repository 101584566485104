import { authors, columns, months, years } from "../maps";
import {
  fehm_maghrib,
  fikr_o_nazar,
  idaria,
  mansoosaat,
  sharai_rehnumai,
  tabadla_e_afkaar,
  mutala_e_seerat,
  tanqeehaat,
  fiqh_almaliyat,
  mukaalma,
  aaina_watan,
  bazm_e_khyal,
  tehleel_o_tajzia,
  tarjuma,
  darsiyat,
} from "./Content/Jan-2022";
import {
  anwaar_e_hayaat_feb_2022,
  bazm_e_khyal_feb_2022,
  darsiyaat_feb_2022,
  fehm_maghrib_feb_2022,
  fikr_o_nazar_feb_2022,
  fiqh_almaliyat_feb_2022,
  idaria_feb_2022,
  jahan_adab_feb_2022,
  mansoosaat_feb_2022,
  mutala_e_seerat_feb_2022,
  sharai_rehnumai_feb_2022,
  tabadla_e_afkaar_feb_2022,
  tanqeehaat_feb_2022,
  tehleel_o_tajzia_feb_2022,
} from "./Content/Feb-2022";
import {
  bazm_e_khyal_mar_2022,
  ehd_e_hazir_mar_2022,
  fehm_maghrib_mar_2022,
  fikr_o_nazar_mar_2022,
  fiqh_almaliyat_mar_2022,
  idaria_mar_2022,
  mansoosaat_mar_2022,
  mukaalma_mar_2022,
  mutala_e_seerat_mar_2022,
  sargazasht_mar_2022,
  sharai_rehnumai_mar_2022,
  siyaasiyaat_mar_2022,
  tanqeehaat_mar_2022,
  tehleel_o_tajzia_mar_2022,
} from "./Content/march-2022";

export const allMagazines = {
  magazines: [
    {
      id: 0,
      currentMonth: true,
      magazineInfo: [
        {
          id: 1,
          columnName: columns.IDARIA,
          topic: ["میرے نبیﷺ کا چلا خاندان بیٹی سے"],
          author: [authors.M_ANAS_RAZA],
          content: [idaria_mar_2022],
        },
        {
          id: 2,
          columnName: columns.MANSOOSAAT,
          topic: ["اسلامی معاشرت کے آداب سورۂ حجرات کی روشنی میں(دوسری قسط)"],
          author: [authors.SHAHZAIB_RAAJPAR],
          content: [mansoosaat_mar_2022],
        },
        {
          id: 3,
          columnName: columns.SHARYI_REHNUMAAI,
          topic: ["کیا فرماتے ہیں علمائے دین؟"],
          author: [authors.MUFTI_MUHAMMAD_FARHAN],
          content: [sharai_rehnumai_mar_2022],
        },
        {
          id: 4,
          columnName: columns.FIKR_O_NAZAR,
          topic: ["سوچ بدل کے دیکھیں!"],
          author: [authors.ALLAMA_M_SAAD],
          content: [fikr_o_nazar_mar_2022],
        },
        {
          id: 5,
          columnName: columns.FEHM_MAGHRIB,
          topic: ["الحادی شبہات کے جوابات (دوسری قسط)"],
          author: [authors.ALLAMA_M_ANAS],
          content: [fehm_maghrib_mar_2022],
        },
        {
          id: 6,
          columnName: columns.MUTALA_E_SEERAT,
          topic: ["عد بعثت معاشرتی امن کے لیے نبی کریمﷺ کا کردار"],
          author: [authors.HAFIZ_AHMED],
          content: [mutala_e_seerat_mar_2022],
        },
        {
          id: 7,
          columnName: columns.TANQEEHAAT,
          topic: ['"جنس"  فقہاء کی نظر میں'],
          author: [authors.GHULAM_RAZA_QADRI],
          content: [tanqeehaat_mar_2022],
        },
        {
          id: 8,
          columnName: columns.FIQH_ALMALIYAAT,
          topic: ["ریاست مدینہ اور قرض کا لین دین"],
          author: [authors.ALLAMA_ASIF_IQBAL],
          content: [fiqh_almaliyat_mar_2022],
        },
        {
          id: 9,
          columnName: columns.MUKAAMLA,
          topic: ["مفتی عبد الرحمن قادری (ملاوی ) سے خصوصی ملاقات"],
          author: [authors.M_ANAS_RAZA],
          content: [mukaalma_mar_2022],
        },
        {
          id: 10,
          columnName: columns.EHD_E_HAZIR,
          topic: ["کرناٹک کا حجابی معاملہ اور بعض مسلمانوں کا دوہرا معیار"],
          author: [authors.BILAL_HANFI],
          content: [ehd_e_hazir_mar_2022],
        },
        {
          id: 11,
          columnName: columns.SARGAZASHT,
          topic: ["مدرسہ انوار القرآن قادریہ رضویہ میں آٹھ سال"],
          author: [authors.ALLAMA_OSAMA_QADRI],
          content: [sargazasht_mar_2022],
        },
        {
          id: 12,
          columnName: columns.BAZM_E_KHYAL,
          topic: ["انسان کیا ہے؟ (دوسرہ قسط)"],
          author: [authors.FURQAN_SHABBIR],
          content: [bazm_e_khyal_mar_2022],
        },
        {
          id: 13,
          columnName: columns.SIYAASIYAAT,
          topic: ["سیاسیت اسلامیہ کے بنیادی خدوخال"],
          author: [authors.ALLAMA_ASJAD_NOMAN],
          content: [siyaasiyaat_mar_2022],
        },
        {
          id: 14,
          columnName: columns.TEHLEEL_O_TAJZIYA,
          topic: ["فن حدیث کا احیاء وقت کا تقاضہ ہے"],
          author: [authors.M_RIZWAN_TAHIR],
          content: [tehleel_o_tajzia_mar_2022],
        },
      ],
      year: years[2022],
      month: months.MARCH_APRIL,
    },
    {
      id: 1,
      name: "خصوصی شمارہ",
      magazineInfo: [
        {
          id: 1,
          columnName: columns.IDARIA,
          topic: ["قانون توہین رسالت 295C غیر موثر کیسے؟"],
          author: [authors.M_ANAS_RAZA],
          content: [idaria],
        },
        {
          id: 2,
          columnName: columns.MANSOOSAAT,
          topic: ["مومن کی صفات"],
          author: [authors.HAFIZ_AHMED],
          content: [mansoosaat],
        },
        {
          id: 3,
          columnName: columns.SHARYI_REHNUMAAI,
          topic: ["کیا فرماتے ہیں علمائے دین؟"],
          author: [authors.MUFTI_MUHAMMAD_FARHAN],
          content: [sharai_rehnumai],
        },
        {
          id: 4,
          columnName: columns.FIKR_O_NAZAR,
          topic: ["آزاد خیالوں سے چار باتیں"],
          author: [authors.ALLAMA_ASIF_IQBAL],
          content: [fikr_o_nazar],
        },
        {
          id: 5,
          columnName: columns.TABADLA_E_KHYAL,
          topic: ["سانحہ سیالکوٹ، ذمہ دار کون؟"],
          author: [authors.ULMA_WA_DANISH],
          content: [tabadla_e_afkaar],
        },
        {
          id: 6,
          columnName: columns.FEHM_MAGHRIB,
          topic: ["وقوعہ سیالکوٹ، دیسی لبرلز اور ہمارا نظام عدل!!"],
          author: [authors.ABDUL_NABI],
          content: [fehm_maghrib],
        },
        {
          id: 7,
          columnName: columns.MUTALA_E_SEERAT,
          topic: ["سیرت النبی عہد بہ عہد"],
          author: [authors.SHAHZAIB_RAAJPAR],
          content: [mutala_e_seerat],
        },
        {
          id: 8,
          columnName: columns.TANQEEHAAT,
          topic: ["وطن اصلی کی تحقیق/سسرال وطن اصلی ہے یا نہیں؟"],
          author: [authors.ALLAMA_SHOAIB_MADNI],
          content: [tanqeehaat],
        },
        {
          id: 9,
          columnName: columns.FIQH_ALMALIYAAT,
          topic: ["بانڈ اور شیئر کا فرق"],
          author: [authors.ALLAMA_MUHAMMAD_SALMAN],
          content: [fiqh_almaliyat],
        },
        {
          id: 10,
          columnName: columns.MUKAAMLA,
          topic: ["علامہ محمد قمر الحسن قمر بستوی صاحب سے خصوصی ملاقات"],
          author: [authors.M_ANAS_RAZA],
          content: [mukaalma],
        },
        {
          id: 11,
          columnName: columns.AAINA_WATAN,
          topic: ["مقناطیسی تحریک"],
          author: [authors.M_BILAL_RIZVI],
          content: [aaina_watan],
        },
        {
          id: 12,
          columnName: columns.BAZM_E_KHYAL,
          topic: ["انسان کو پہچاننا کیوں ضروری ہے؟"],
          author: [authors.FURQAN_SHABBIR],
          content: [bazm_e_khyal],
        },
        {
          id: 13,
          columnName: columns.TEHLEEL_O_TAJZIYA,
          topic: ["حیات الحیوان، تعارف و جائزہ"],
          author: [authors.ALLAMA_M_ANAS],
          content: [tehleel_o_tajzia],
        },
        {
          id: 14,
          columnName: columns.TARJUMA,
          topic: ["استحسان الخوض فی علم الکلام"],
          author: [authors.ALLAMA_SHOAIB_MADNI],
          content: [tarjuma],
        },
        {
          id: 15,
          columnName: columns.DARSIYAAT,
          topic: ["تقریر  باندیالوی بر تفسیر بیضاوی (پہلی قسط)"],
          author: [authors.M_ANAS_RAZA],
          content: [darsiyat],
        },
      ],
      year: years[2022],
      month: months.JANUARY,
    },
    {
      id: 2,
      name: "خصوصی شمارہ",
      magazineInfo: [
        {
          id: 1,
          columnName: "اداریہ",
          topic: ["بدمعاشی بدحالی میں ڈوبا افغانستان"],
          author: [authors.M_ANAS_RAZA],
          content: [idaria_feb_2022],
        },
        {
          id: 2,
          columnName: columns.MANSOOSAAT,
          topic: ["اسلامی معاشرت کے آداب سورہ حجرات کی روشنی میں(پہلی قسط)"],
          author: [authors.SHAHZAIB_RAAJPAR],
          content: [mansoosaat_feb_2022],
        },
        {
          id: 3,
          columnName: columns.SHARYI_REHNUMAAI,
          topic: ["کیا فرماتے ہیں علمائے دین؟"],
          author: [authors.MUFTI_MUHAMMAD_FARHAN],
          content: [sharai_rehnumai_feb_2022],
        },
        {
          id: 4,
          columnName: columns.FIKR_O_NAZAR,
          topic: ["روشن مستقبل کا ایک اہم مسئلہ"],
          author: [authors.ALLAMA_M_SAAD],
          content: [fikr_o_nazar_feb_2022],
        },
        {
          id: 5,
          columnName: columns.TABADLA_E_KHYAL,
          topic: ["مروجہ نعت خوانی اسلام کی ترویج میں کس قدر معاون ہے؟"],
          author: [authors.ULMA_WA_DANISH],
          content: [tabadla_e_afkaar_feb_2022],
        },
        {
          id: 6,
          columnName: columns.FEHM_MAGHRIB,
          topic: ["الحادی شبہات کے جوابات (پہلی قسط)"],
          author: [authors.ALLAMA_M_ANAS],
          content: [fehm_maghrib_feb_2022],
        },
        {
          id: 7,
          columnName: columns.MUTALA_E_SEERAT,
          topic: ["قبل بعثت معاشرتی امن کے لئے نبی کریم ﷺ کا کردار"],
          author: [authors.HAFIZ_AHMED],
          content: [mutala_e_seerat_feb_2022],
        },
        {
          id: 8,
          columnName: columns.TANQEEHAAT,
          topic: ["جانوروں اور پرندوں کی حلت و حرمت"],
          author: [authors.M_NADEEM_ATTARI],
          content: [tanqeehaat_feb_2022],
        },
        {
          id: 9,
          columnName: columns.FIQH_ALMALIYAAT,
          topic: ["ریاست مدینہ اور اسلامی بینکاری"],
          author: [authors.ALLAMA_ASIF_IQBAL],
          content: [fiqh_almaliyat_feb_2022],
        },
        {
          id: 10,
          columnName: columns.ANWAAR_E_HAYAAT,
          topic: ["ملفوظات خواجہ غریب نواز رحمة اللہ علیہ"],
          author: [authors.ALLAMA_M_JAVED],
          content: [anwaar_e_hayaat_feb_2022],
        },
        {
          id: 11,
          columnName: columns.BAZM_E_KHYAL,
          topic: ["انسان کیا ہے؟ (پہلی قسط)"],
          author: [authors.FURQAN_SHABBIR],
          content: [bazm_e_khyal_feb_2022],
        },
        {
          id: 12,
          columnName: columns.JAHAN_ADAB,
          topic: ["جناب STE---- ڈیش صاحب"],
          author: [authors.BABR_RAUF],
          content: [jahan_adab_feb_2022],
        },
        {
          id: 13,
          columnName: [columns.TEHLEEL_O_TAJZIYA],
          topic: ["کتاب الرسالة تعارف و جائزہ"],
          author: authors.ALLAMA_M_JAVED,
          content: [tehleel_o_tajzia_feb_2022],
        },
        {
          id: 14,
          columnName: columns.DARSIYAAT,
          topic: ["تقریر بندیالوی بر تفسیر بیضاوی (دوسری قسط)"],
          author: [authors.M_ANAS_RAZA],
          content: [darsiyaat_feb_2022],
        },
      ],
      year: years[2022],
      month: months.FEBRUARY,
    },
    {
      id: 3,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2021],
      month: months.NOVEMBER_DECEMBER,
    },
    {
      id: 4,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2021],
      month: months.SEPTEMBER_OCTOBER,
    },
    {
      id: 5,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2021],
      month: months.AUGUST,
    },
    {
      id: 6,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2021],
      month: months.JULY,
    },
    {
      id: 7,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2021],
      month: months.JUNE,
    },
    {
      id: 8,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2021],
      month: months.APRIL,
    },
    {
      id: 9,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2021],
      month: months.MARCH,
    },
    {
      id: 10,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2021],
      month: months.FEBRUARY,
    },
    {
      id: 11,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2021],
      month: months.JANUARY,
    },
    {
      id: 12,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2020],
      month: months.DECEMBER,
    },
    {
      id: 13,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2020],
      month: months.NOVEMBER,
    },
    {
      id: 14,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2020],
      month: months.OCTOBER,
    },
    {
      id: 15,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2020],
      month: months.SEPTEMBER,
    },
    {
      id: 16,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2020],
      month: months.AUGUST,
    },
    {
      id: 17,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2020],
      month: months.MARCH,
    },
    {
      id: 18,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2020],
      month: months.FEBRUARY,
    },
    {
      id: 19,
      name: "خصوصی شمارہ",
      magazineInfo: [],
      year: years[2020],
      month: months.JANUARY,
    },
  ],
};
