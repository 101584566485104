import React from 'react';
import CurrentMonthMagazine from './CurrentMonthMagazine'
import './Home.css'

import AllMagazines from './AllMagazines';

const Home = () => {

    return (
        <div className="container">
                <CurrentMonthMagazine />               
                <AllMagazines />                    
        </div>
    )
}

export default Home
