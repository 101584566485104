import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Card from "../common/Card";
import Expand from "../common/Expand";
import { allMagazines } from "../constants/DataObject.js/AllMagazine";
import { urduMonthsToEnglish } from "../constants/maps";
import "./CurrentMonthMagazine.css";

// const getCurrentMonthMagazineApi = () => Api.get(`/magazine/currentMonth`, null,  { headers: false });

const CurrentMonthMagazine = () => {
  const navigate = useNavigate();

  // const { data, loading, error, request } = useApi(() => getCurrentMonthMagazineApi());

  // useEffect(() => {
  //     request();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const magazine = allMagazines.magazines.find(
    (magazine) => magazine.currentMonth
  );

  return (
    <div className="CurrentMonthMagazine">
      <Row className="CurrentMonthMagazine_Row">
        <Col xs={12} sm={12} md={6} lg={4}>
          <Row className="CurrentMonthMagazine_SubRow">
            <Col className="CurrentMonthMagazine_SubCol">
              <span className="CurrentMonthMagazine_SubColText">
                PDF ڈاونلوڈ کرنے کے لیے میگزین پر کلک کریں۔
              </span>
              <Card onClick={() => navigate(`/magazine-info/${magazine.id}`)}>
                <img
                  loading="lazy"
                  width="300"
                  height="300"
                  src={`${process.env.PUBLIC_URL}/${
                    urduMonthsToEnglish[magazine.month]
                  }-${magazine.year}.jpg`}
                  alt={"abc"}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <p className="CurrentMonthMagazine_ExpandHeading">
            مخزنِ علم - {magazine.month} {magazine.year}
          </p>
          {magazine.magazineInfo.map((info) => (
            <Expand key={magazine.id} magazineInfo={info} />
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default CurrentMonthMagazine;
