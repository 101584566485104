import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { classNames } from "../helpers";
import "./ExpandHeading.css";

const ExpandHeading = ({ columnName, expandOpened, setExpandOpened }) => {
  return (
    <>
      <span>
        {expandOpened ? (
          <AiOutlineMinus
            className={classNames(
              "ExpandHeading_Icon",
              expandOpened && "Expand_Icon-active"
            )}
            onClick={() => setExpandOpened(!expandOpened)}
          />
        ) : (
          <AiOutlinePlus
            className="ExpandHeading_Icon"
            onClick={() => setExpandOpened(!expandOpened)}
          />
        )}
      </span>
      <div className="ExpandHeading_RowHeading">{columnName}</div>
    </>
  );
};

export default ExpandHeading;
